// extracted by mini-css-extract-plugin
export var accountDetails = "styles-module--account-details--5491c";
export var accountDetailsContainer = "styles-module--account-details-container--e3732";
export var accountDetails__action = "styles-module--account-details__action--96213";
export var accountDetails__card = "styles-module--account-details__card--448dd";
export var accountDetails__detail = "styles-module--account-details__detail--062cd";
export var accountDetails__label = "styles-module--account-details__label--23700";
export var addAddress = "styles-module--add-address--0b788";
export var addressCard = "styles-module--address-card--a35e4";
export var addressCard__actions = "styles-module--address-card__actions--647c8";
export var addressCard__address = "styles-module--address-card__address--3d5b3";
export var addressCard__content = "styles-module--address-card__content--82000";
export var addressCard__default = "styles-module--address-card__default--192a5";
export var addressContainer = "styles-module--address-container--95b95";
export var addressContainer__address = "styles-module--address-container__address--3bc92";
export var addressContainer__title = "styles-module--address-container__title--3470c";
export var awesome = "styles-module--awesome--1214d";
export var bold = "styles-module--bold--23cef";
export var center = "styles-module--center--d319b";
export var h1 = "styles-module--h1--c0cbe";
export var h1Bold = "styles-module--h1--bold--761a2";
export var h1Uc = "styles-module--h1--uc--bc65e";
export var h2 = "styles-module--h2--18891";
export var h2Bold = "styles-module--h2--bold--2e65f";
export var h2Uc = "styles-module--h2--uc--47bb6";
export var h3 = "styles-module--h3--fc75e";
export var h3Bold = "styles-module--h3--bold--2ce8a";
export var h3Uc = "styles-module--h3--uc--784c1";
export var h4 = "styles-module--h4--13f61";
export var h4Bold = "styles-module--h4--bold--ae368";
export var h4Uc = "styles-module--h4--uc--8f79a";
export var linkUnderline = "styles-module--link--underline--a7f33";
export var microtext = "styles-module--microtext--96537";
export var microtextBold = "styles-module--microtext--bold--4f597";
export var microtextUc = "styles-module--microtext--uc--73fc3";
export var nanotext = "styles-module--nanotext--259b4";
export var strikethrough = "styles-module--strikethrough--e2950";
export var strikethroughBold = "styles-module--strikethrough--bold--fb043";
export var strikethroughLg = "styles-module--strikethrough--lg--ccbc6";
export var strikethroughSm = "styles-module--strikethrough--sm--a1635";
export var subtext = "styles-module--subtext--98aa4";
export var subtextBold = "styles-module--subtext--bold--560c0";
export var subtextUc = "styles-module--subtext--uc--7c921";
export var text = "styles-module--text--e3a87";
export var textBold = "styles-module--text--bold--485e4";
export var textUc = "styles-module--text--uc--5f7b2";
export var titleL = "styles-module--title-l--ea627";
export var titleM = "styles-module--title-m--c040f";
export var titleS = "styles-module--title-s--27c7b";
export var titleXl = "styles-module--title-xl--a2fd0";
export var titleXs = "styles-module--title-xs--96a9d";
export var titleXxs = "styles-module--title-xxs--05d58";
export var uc = "styles-module--uc--90614";
export var underline = "styles-module--underline--bb8ae";